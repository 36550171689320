import * as React from "react";
import {
  Avatar,
  Button,
  Container,
  Grid,
  Typography,
  Link,
} from "@mui/material";
import { getCookie } from "cookies-next";
import { useRouter } from "next/router";
import styles from "../../styles/Home.module.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import useEffect from "../../hooks/useEffectOne";
import { useDispatch } from "react-redux";
import { toggleModal } from "../../redux/reducers/authData";
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit">booclii.com</Link> {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
const companyFooter = [
  {
    title: "booclii",
    description: [
      {
        title: "Address:",
        subTitle:
          " No 10 soi Prachautid 4-1-6 , Prachautid Road , Ratburana, Bangkok 10140",
        icon:
          "https://firebasestorage.googleapis.com/v0/b/uploadfile-cc5a1.appspot.com/o/Booclii%2Fli_map-pin.png?alt=media&token=50437ca5-3d21-4ac3-a43e-49af29b706b8",
      },

      {
        title: "Email:",
        subTitle: "hello@booclii.com",
        icon:
          "https://firebasestorage.googleapis.com/v0/b/uploadfile-cc5a1.appspot.com/o/Booclii%2Fli_mail.png?alt=media&token=45882ffe-f740-4df3-a65e-3abf7d7c8849",
      },
      {
        title: "Work hours:",
        subTitle: "24 hours",
        icon:
          "https://firebasestorage.googleapis.com/v0/b/uploadfile-cc5a1.appspot.com/o/Booclii%2Fli_clock-9.png?alt=media&token=429d8d90-b7ca-48e8-9ffd-1facdd8a153b",
      },
    ],
  },
];

const footers = [
  {
    title: "Account",
    description: [
      { name: "Wishlist", path: "wishlists", isAuth: true },
      { name: "Chart", path: "cart_order", isAuth: true },
      { name: "Track order", path: "booking_order", isAuth: true },
      { name: "Shipping Details", path: "", isAuth: true },
    ],
  },
  {
    title: "Useful links",
    description: [
      { name: "About Us", path: "about", isAuth: false },
      { name: "Contact", path: "", isAuth: false },
      { name: "Hot deals", path: "", isAuth: false },
      { name: "Promotions", path: "", isAuth: false },
      { name: "New products", path: "", isAuth: false },
    ],
  },
  {
    title: "Help Center",
    description: [
      { name: "Payments", path: "", isAuth: false },
      { name: "Refund", path: "", isAuth: false },
      { name: "Checkout", path: "", isAuth: false },
      { name: "Shipping", path: "", isAuth: false },
      { name: "Q&A", path: "", isAuth: false },
      { name: "Privacy Policy", path: "privacy_policy", isAuth: false },
    ],
  },
];
const paymentMethods = [
  {
    image: "https://cdn.visa.com/v2/assets/images/logos/visa/blue/logo.png",
    linkUrl: "https://www.visa.com/",
  },
  {
    image:
      "https://logos-world.net/wp-content/uploads/2020/09/Mastercard-Logo.png",
    linkUrl: "https://www.mastercard.com/",
  },
  {
    image:
      "https://upload.wikimedia.org/wikipedia/commons/4/4d/Maestro_logo.png",
    linkUrl: "https://www.mastercard.com/",
  },
  {
    image:
      "https://toppng.com/uploads/preview/american-express-logo-vector-eps-ai-11573864206smbszn8xfq.png",
    linkUrl: "https://www.americanexpress.com/",
  },
];

export default function Footer() {
  const router = useRouter();
  const dispatch = useDispatch();
  const [name, setName] = React.useState("");
  useEffect(() => {
    getAuthenticate();
  });
  const getAuthenticate = () => {
    const user = getCookie("name");

    setName(user === undefined ? "" : user);
  };
  const redirectionTo = (item) => {
    if (name !== "") {
      if (item.path !== "") {
        router.push(`${router.locale}/${item.path}`);
      } else {
        console.log("5555");
      }
    } else {
      if (item.isAuth === false) {
        router.push(`${router.locale}/${item.path}`);
      } else {
        dispatch(toggleModal());
      }
    }
  };
  const imageClick = (url) => {
    window.open(url);
  };
  return (
    <React.Fragment>
      {/* Footer */}
      <div>
        <Container
          maxWidth="string"
          component="footer"
          sx={{
            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
            py: [3, 6],
          }}
        >
          <Grid container spacing={4} justifyContent="space-evenly">
            {companyFooter.map((footer) => (
              <Grid item xs={5} sm={3} key={footer.title}>
                <Typography variant="h6" className={styles.textLogo}>
                  {footer.title}
                </Typography>
                <ul>
                  {footer.description.map((item, i) => (
                    <li
                      key={i}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: "10px",
                      }}
                    >
                      <img
                        src={item.icon}
                        alt={`icon${i}`}
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "10px",
                        }}
                      />

                      <Link
                        href="#"
                        underline="none"
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Typography
                          style={{ fontWeight: "bold", color: "black" }}
                        >
                          {item.title}
                        </Typography>
                        <Typography style={{ color: "black" }}>
                          {item.subTitle}
                        </Typography>
                      </Link>
                    </li>
                  ))}
                </ul>
              </Grid>
            ))}
            {footers.map((footer) => (
              <Grid item xs={4} sm={2} key={footer.title}>
                <Typography variant="h6" color="text.primary" gutterBottom>
                  {footer.title}
                </Typography>
                <ul>
                  {footer.description.map((item, index) => (
                    <li key={index}>
                      <Link
                        href="#"
                        onClick={() => redirectionTo(item)}
                        underline="none"
                        variant="subtitle1"
                        color="text.secondary"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Grid>
            ))}
          </Grid>
        </Container>
      </div>
      <div>
        <Container
          maxWidth="string"
          component="footer"
          sx={{
            py: [1, 2],
          }}
          style={{
            backgroundColor: "#E8F8F2",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ marginTop: 10 }}>
            <Typography variant="body2" style={{ color: "#3E5A50" }}>
              {"© "}

              {new Date().getFullYear()}
              {", All rights reserved"}
            </Typography>
          </div>
          <div style={{ marginTop: 8 }}>
            {paymentMethods.map((item, i) => (
              <Link key={i} href="#" onClick={() => imageClick(item.linkUrl)}>
                <img
                  src={item.image}
                  alt={`payment${i}`}
                  style={{
                    width: "50px",
                    height: "25px",
                    marginRight: "10px",
                  }}
                />
              </Link>
            ))}
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Avatar style={{ backgroundColor: "#3E5A50", marginRight: 10 }}>
              <Button
                onClick={() => window.open("https://www.facebook.com/booclii")}
              >
                <FacebookIcon style={{ color: "white" }} />
              </Button>
            </Avatar>
            <Avatar style={{ backgroundColor: "#3E5A50", marginRight: 10 }}>
              <Button
                onClick={() =>
                  window.open("https://www.linkedin.com/company/booclii/")
                }
              >
                <LinkedInIcon style={{ color: "white" }} />
              </Button>
            </Avatar>
            <Avatar style={{ backgroundColor: "#3E5A50", marginRight: 10 }}>
              <Button
                onClick={() =>
                  window.open("https://www.instagram.com/booclii/ ")
                }
              >
                <InstagramIcon style={{ color: "white" }} />
              </Button>
            </Avatar>
            <Avatar style={{ backgroundColor: "#3E5A50", marginRight: 10 }}>
              <Button
                onClick={() => window.open("https://www.facebook.com/booclii")}
              >
                <TwitterIcon style={{ color: "white" }} />
              </Button>
            </Avatar>
          </div>
        </Container>
      </div>
      {/* End footer */}
    </React.Fragment>
  );
}
